import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { SpecificChartFunctionality } from '../../constants/common'
import ChartLayout from '../../models/chart_layout'
import { decodeQueryString, encodeQueryString } from '../../utils/query_string'
import {
  DEFAULT_TYPES,
  FREE_TYPES_QUOTE,
  FREE_TYPES_QUOTE_VALUES,
  QuoteLongToShortType,
  QuoteNodeChartTypes,
  QuoteShortToLongType,
  QuoteUrlChartTypes,
  TypeBaseOption,
} from './utils'

export function useChartTypes({ isPremium, model }: { isPremium: boolean; model: ChartLayout }) {
  const location = useLocation()
  const { b, t, ta, ty, p = 'd', r } = decodeQueryString(location.search)
  const taInt = ta ? Number.parseInt(ta) : 1
  const isQuoteOrQuoteFinancials = [
    SpecificChartFunctionality.quotePage,
    SpecificChartFunctionality.quoteFinancials,
  ].includes(model.specificChartFunctionality as SpecificChartFunctionality)

  const getCurrentType = React.useCallback(() => {
    const defaultType =
      ((ty as QuoteUrlChartTypes | undefined) && QuoteShortToLongType[ty as QuoteUrlChartTypes]) ??
      (window.FinvizQuoteTypeCurrent as QuoteNodeChartTypes)

    return !isPremium && defaultType === QuoteNodeChartTypes.candlestick && taInt === 0
      ? QuoteNodeChartTypes.candlesticksimple
      : defaultType
  }, [isPremium, taInt, ty])

  const generateRouterPath = React.useCallback(
    (type: QuoteNodeChartTypes) => {
      if (isPremium || FREE_TYPES_QUOTE_VALUES.includes(type)) {
        return `${location.pathname}?${encodeQueryString({
          b,
          t,
          r,
          ty:
            type === QuoteNodeChartTypes.candlesticksimple
              ? QuoteLongToShortType.candlestick
              : QuoteLongToShortType[type],
          ta: type === QuoteNodeChartTypes.candlestick ? '1' : '0',
          p,
        })}`
      }
      return `/elite.ashx?${encodeQueryString({
        utm_source: 'finviz',
        utm_medium: 'banner',
        utm_campaign: 'quote-chart-type-select',
      })}`
    },
    [isPremium, location.pathname, b, t, r, p]
  )

  const chartTypes = React.useMemo(() => {
    const options = isPremium || !isQuoteOrQuoteFinancials ? DEFAULT_TYPES : FREE_TYPES_QUOTE

    return options.map((item) => ({
      ...item,
      href: generateRouterPath(item.value as QuoteNodeChartTypes),
      reloadDocument: !isPremium,
    })) as TypeBaseOption[]
  }, [generateRouterPath, isPremium, isQuoteOrQuoteFinancials])

  return { getCurrentType, chartTypes }
}
