import { CanvasRenderingContext2D as NodeCanvasRenderingContext2D } from 'skia-canvas'

import {
  CanvasElementType,
  ChartEditorEnum,
  ChartElementType,
  ChartEventType,
  IndicatorType,
  LayoutType,
  OverlayType,
  ScaleType,
  SpecificChartFunctionality,
  TIMEFRAME,
  TextAlign,
  TextBaseline,
} from '../app/constants/common'
import ChartModel from '../app/models/chart'
import ChartLayout from '../app/models/chart_layout'
import { IChartThemeColors } from '../app/models/constants'

interface ISentry {
  captureException: (exception: any, captureContext?: { extra: ObjectHash }) => any
  captureMessage: (message: string, captureContext?: { extra: ObjectHash }) => any
}

declare global {
  interface Window {
    Sentry: ISentry
    FinvizSettings: ObjectHash
    FinvizChartsSettings: ObjectHash
    globalChartConfig: RootChartConfigObject
    isRenderInProgress: boolean | number
    rendersInProgress: number[]
    FinvizQuoteTypeCurrent: string
    handleScriptNotLoaded: () => void
    renderScriptNotLoaded: () => void
    gtag?: any
  }

  interface Document {
    fullscreenElement: any
    webkitFullscreenElement?: () => void
    mozFullScreenElement?: () => void
    msFullscreenElement?: () => void

    webkitExitFullscreen?: () => void
    mozExitFullScreen?: () => void
    msExitFullscreen?: () => void
  }

  interface HTMLElement {
    webkitRequestFullScreen?: () => void
    mozRequestFullScreen?: () => void
    msRequestFullscreen?: () => void
  }

  interface Navigator {
    msMaxTouchPoints: any
  }

  interface Array<T> {
    last(): T | undefined
  }

  interface String {
    padLeft(paddingValue: string): string
    startsWith(str: string): boolean
  }

  type CanvasCache = Partial<Record<keyof CanvasRenderingContext2D, any>>

  interface CanvasRenderingContext2D {
    _cache: CanvasCache
    // We need to omit `canvas` because it is read only
    set<Key extends keyof Omit<CanvasRenderingContext2D, 'canvas'>>(
      key: Key,
      value: CanvasRenderingContext2D[Key]
    ): void
  }

  interface HTMLCanvasElement {
    _cache: CanvasCache
  }

  interface ImageBitmapRenderingContext {
    _cache: CanvasCache
  }

  type RenderingContext2DType = CanvasRenderingContext2D | NodeCanvasRenderingContext2D
}

declare module 'skia-canvas' {
  interface CanvasRenderingContext2D {
    _cache: CanvasCache

    set<Key extends keyof Omit<CanvasRenderingContext2D, 'canvas'>>(
      key: Key,
      value: CanvasRenderingContext2D[Key]
    ): void
  }
}

export type TodoObjectHashAnyType = Record<string, any>
export type TodoAreaObject = Record<string, any>
export type ObjectHash<T = any, K extends string | number = string> = Record<K, T>
export type EmptyObject = Record<never, never>

export type TodoAnyType = any

export type ThumbType = string

export type PaneArea<T extends ObjectHash = EmptyObject> = {
  mouseDown?: boolean
  x: number
  y: number
  scaled: {
    x: number
    y: number
  }
  width: number
  height: number
  cursorX: number
  cursorY: number
  isCursorInChartWidthBoundaries: boolean
} & T

export type Maybe<T> = T | null | undefined

export interface DrawingBorder {
  color: string
  width: number
}

export interface TextAttrs {
  text?: string
  background: string
  border?: { width: number; color: string }
  lineWidth?: number
  font: {
    size: number
    family?: string
    style?: string
    weight?: string
  }
  fillStyle?: string
  strokeStyle?: string
  angle?: number
  lineHeight: number
  textAlign?: TextAlign
  textBaseline: TextBaseline
  padding: {
    top: number
    right: number
    bottom: number
    left: number
  }
}

export interface Point {
  x: number
  y: number
}

export type RequireByKey<T extends ObjectHash, K extends keyof T = keyof T> = Omit<T, K> & Required<Pick<T, K>>

export interface ResizeByThumbFuncProps<T extends ObjectHash = EmptyObject> {
  type: ThumbType
  difX: number
  difY: number
  area: PaneArea<T>
}

export type ResizeByThumbWithTypeAndDifs = RequireByKey<ResizeByThumbFuncProps, 'type' | 'difX' | 'difY'>

export interface ChartConfigChartPaneElement {
  type: ChartElementType | CanvasElementType | IndicatorType | OverlayType | ChartEventType
  overlays?: OverlayConfigObject[]
  period?: string
  code?: string
  elementId?: string | null
  zIndex?: number
}

export interface RendererChartBarProps {
  index: number
  chartSettingsColors: IChartThemeColors
  hasFillAndBorder?: boolean
}

export interface ChartConfigChartPane {
  elements: ChartConfigChartPaneElement[]
  height: number
  chart?: ChartConfigObject | ChartModel
}

export interface IIdea {
  id?: number
  date?: string
  note: string
}

export interface ChartConfigObjectExtended {
  editable: boolean
  canChangeTicker: boolean
  scrollable: boolean
  cross: boolean
  ideas: boolean
  editors: ChartEditorEnum[]
  specificChartFunctionality: SpecificChartFunctionality
  theme: Theme
  layout: LayoutType
  uuid?: string
  idea?: IIdea
}

export interface ChartConfigObject {
  width: number
  height: number
  panes: ChartConfigChartPane[]
  premarket: boolean
  aftermarket: boolean
  dateRange: string | null
  hasChartEvents?: boolean
  stretch?: boolean
  ticker: string
  instrument: Instrument
  timeframe: TIMEFRAME
  chart_layout: ChartLayout
  zoomFactor: number
  leftOffset: number | null
  refreshData: boolean | number
  isHideDrawingsActive: boolean
  isScrolled?: boolean
  scale: ScaleType
}

export type RootChartConfigObjectChart = Omit<
  ChartConfigObject,
  'chart_layout' | 'leftOffset' | 'zoomFactor' | 'isHideDrawingsActive'
>
export interface RootChartConfigObject extends Partial<ChartConfigObjectExtended> {
  charts: Array<RootChartConfigObjectChart>
  width: number
  height: number
  newCharts?: boolean
  useGrid?: boolean
}

export interface OverlayConfigObject {
  color: string
  period: string
  type: OverlayType
}

export enum Instrument {
  Stock = 'stock',
  Crypto = 'crypto',
  MarketSentiment = 'market_sentiment',
  Futures = 'futures',
  Forex = 'forex',
  Group = 'group',
}

export enum Theme {
  light = 'light',
  dark = 'dark',
}

export interface IModalConfig {
  title: string
  inputs: IModalConfigInput[]
  inputsErrorMessages: ObjectHash
}
export interface IOptionType {
  value: string | number
  label: string
}
export interface IModalConfigInput {
  type: React.HTMLInputTypeAttribute
  label: string
  name: string
  value: number | string
  required?: boolean
  min?: number
  color?: string
  step?: number
  items?: IOptionType[]
}

// Keep in sync with config/types/globals.d.ts
export enum Position {
  Above = 'above',
  Below = 'below',
}

export enum DefaultSpineEvents {
  Change = 'change',
  Destroy = 'destroy',
  Refresh = 'refresh',
  Update = 'update',
}

export enum CustomSpineEvents {
  IndicatorsChange = 'indicatorsChange',
  ManualChange = 'manualChange',
}

export enum DrawingSpineOptionsEvent {
  Remove = 'remove',
}

export interface DrawingSpineOptions {
  eventType: DrawingSpineOptionsEvent
}

export type TodoButtonProps = ObjectHash
export type TodoButtonTheme = string
