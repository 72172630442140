import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  fastPeriod: number
  slowPeriod: number
  signalPeriod: number
}

export interface Attrs extends Options {
  period: string
}

export class MACDConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Macd
  static abbreviation = 'macd'
  static label = 'MACD'
  static shortLabel = 'MACD'
  static optionsOrder = ['fastPeriod', 'slowPeriod', 'signalPeriod'] as const
  static optionsLabels = { fastPeriod: 'Period', slowPeriod: 'Slow', signalPeriod: 'Signal' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
  static calculatedValuesOrder = ['macd', 'signal', 'histogram'] as const
  static calculatedValuesLabels = { macd: 'MACD Line', signal: 'Signal', histogram: 'Histogram' }
  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
