import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export type Options = {
  period: number
}

export interface Attrs extends Options {
  color: string
}

export class EMAConfig extends BaseConfig {
  static type = OverlayType.Ema
  static abbreviation = 'ema'
  static label = 'Exponential Moving Average'
  static shortLabel = 'EMA'
  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = ['period', 'color'] as Array<keyof Attrs>
  static inputsLabels = { period: 'Period', color: 'Color' }
  static calculatedValuesOrder = ['ema'] as const
  static calculatedValuesLabels = { ema: 'EMA' }
  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
