import math from '../helpers/math'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { OBVConfig } from './configs/obv'
import Indicator from './indicator'

class OBV extends Indicator {
  static config = OBVConfig

  obv: number[] = []

  compute() {
    if (this.isComputeNecessary()) {
      this.obv = [0]

      for (let i = 1; i < this.data.volume.length; i++) {
        let currentObv = this.obv[i - 1]
        if (this.data.close[i] > this.data.close[i - 1]) {
          currentObv += this.data.volume[i]
        } else if (this.data.close[i] < this.data.close[i - 1]) {
          currentObv -= this.data.volume[i]
        }

        this.obv[i] = currentObv
      }

      this.lastValue = this.obv.last() ?? null
    }
    const { min, max } = this.obv.length > 0 ? this.computeVisibleMinMax(this.obv) : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    const dataIndex = this.data.barToDataIndex[index]
    return [
      {
        color: this.getChartLayoutSettings().IndicatorSettings.general.Colors.line!,
        text: this.getValueLabel(this.obv[dataIndex]),
      },
    ]
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    context.set('strokeStyle', this.getChartLayoutSettings().IndicatorSettings.general.Colors.line)
    context.translate(0.5, 0.5)
    context.beginPath()
    drawInVisibleArea({
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      drawBarCallback: (i: number, x: number) => {
        context.lineTo(x, Math.round(this.fy(this.obv[i])))
      },
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  formatAxis(value: number) {
    return math.formatBigNumber(value, 2)
  }

  getModalConfig() {
    return {
      title: OBVConfig.label,
      inputs: [],
      inputsErrorMessages: {},
    }
  }
}

export default OBV
