import { ChartConfigChartPaneElement, RequireByKey } from '../../types/shared'
import AverageTrueRange from '../helpers/atr'
import { Attrs, StrConfig } from './configs/str'
import Overlay from './overlay'

const DEFAULT_PARAMETERS = {
  Period: 7,
  Multiplier: 3.0,
}

const COLORS = {
  Uptrend: '#00a000',
  Downtrend: '#c80000',
}

function parsePeriod(periodStr: string) {
  const values = periodStr.split(',')
  const period = parseInt(values[0]) || DEFAULT_PARAMETERS.Period
  const multiplier = parseFloat(values[1]) || DEFAULT_PARAMETERS.Multiplier

  return [period, multiplier]
}

class Supertrend extends Overlay<Attrs> {
  static config = StrConfig

  static getNumOfBarsBuffer({ period }: RequireByKey<ChartConfigChartPaneElement, 'period'>) {
    const [periodInt] = parsePeriod(period)
    return periodInt + 1
  }

  set(obj: Partial<Attrs>) {
    super.set(obj)
    const { period } = obj
    if (typeof period === 'string') {
      const [periodInt, multiplier] = parsePeriod(period)
      this.attrs.period = periodInt
      this.attrs.multiplier = multiplier
      this.trigger('change')
    }
    return this
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    const { leftOffset, width } = this.model.chart()
    const { period, multiplier } = this.attrs
    const { high, low, close } = this.data
    const atr = new AverageTrueRange(period)
    const finalUpperBand: number[] = []
    const finalLowerBand: number[] = []
    const supertrend: boolean[] = []
    let firstBarToRender
    let lastBarToRender = 0

    context.translate(0.5, 0.5)
    context.beginPath()
    context.set('lineWidth', 1)
    context.set('strokeStyle', this.attrs.uptrendColor)
    for (let i = 1; i < close.length; i++) {
      atr.add(high[i], low[i], close[i - 1])

      // compute bands
      const avg = (high[i] + low[i]) / 2
      const upperBand = avg + multiplier * atr.value
      const lowerBand = avg - multiplier * atr.value

      finalUpperBand[i] = upperBand
      finalLowerBand[i] = lowerBand

      // identify supertrend
      if (close[i] > finalUpperBand[i - 1]) {
        supertrend[i] = true
      } else if (close[i] < finalLowerBand[i - 1]) {
        supertrend[i] = false
      } else {
        supertrend[i] = supertrend[i - 1]
      }

      // adjust bands
      if (supertrend[i] && finalLowerBand[i] < finalLowerBand[i - 1]) {
        finalLowerBand[i] = finalLowerBand[i - 1]
      }
      if (!supertrend[i] && finalUpperBand[i] > finalUpperBand[i - 1]) {
        finalUpperBand[i] = finalUpperBand[i - 1]
      }

      const x = this.fx(i)
      if (i < period || x + leftOffset < 0) {
        continue
      }
      if (this.fx(i - 1) + leftOffset > width) {
        break
      }
      if (firstBarToRender === undefined) firstBarToRender = i

      if (supertrend[i] && !supertrend[i - 1]) {
        // buy
        context.moveTo(this.fx(i - 1), Math.round(this.fy(finalUpperBand[i - 1])))
        context.lineTo(x, Math.round(this.fy(finalLowerBand[i])))
      } else if (supertrend[i]) {
        // keep buying
        context.lineTo(x, Math.round(this.fy(finalLowerBand[i])))
      } else {
        // keep selling
        context.moveTo(x, Math.round(this.fy(finalLowerBand[i])))
      }
      lastBarToRender = i
    }
    context.stroke()

    if (firstBarToRender === undefined) {
      context.translate(-0.5, -0.5)
      return
    }

    context.beginPath()
    context.set('strokeStyle', this.attrs.downtrendColor)
    for (let i = firstBarToRender; i <= lastBarToRender; i++) {
      const x = this.fx(i)
      if (!supertrend[i] && supertrend[i - 1]) {
        // sell
        context.moveTo(this.fx(i - 1), Math.round(this.fy(finalLowerBand[i - 1])))
        context.lineTo(x, Math.round(this.fy(finalUpperBand[i])))
      } else if (!supertrend[i]) {
        // keep selling
        context.lineTo(x, Math.round(this.fy(finalUpperBand[i])))
      } else {
        // keep buying
        context.moveTo(x, Math.round(this.fy(finalUpperBand[i])))
      }
    }
    context.stroke()

    context.translate(-0.5, -0.5)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.attrs.period ?? DEFAULT_PARAMETERS.Period,
        required: true,
        min: 1,
        max: 999999,
      },
      multiplier: {
        type: 'number',
        label: 'Period',
        name: 'multiplier',
        value: this.attrs.multiplier ?? DEFAULT_PARAMETERS.Multiplier,
        required: true,
        step: 0.1,
        min: 0.0,
        max: 999,
      },
      uptrendColor: {
        type: 'color',
        label: 'Uptrend Color',
        name: 'uptrendColor',
        value: this.attrs.uptrendColor ?? this.getFreeColor(),
      },
      downtrendColor: {
        type: 'color',
        label: 'Downtrend Color',
        name: 'downtrendColor',
        value: this.attrs.downtrendColor ?? this.getFreeColor(),
      },
    }

    return {
      title: StrConfig.label,
      inputs: StrConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
        multiplier: `${options.multiplier.label} must be a number between ${options.multiplier.min} and ${options.multiplier.max}`,
      },
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      case 'multiplier':
        return this.getIsNumberInputValid({ key, integerOnly: false })
      case 'uptrendColor':
      case 'downtrendColor':
        return true
      default:
        return false
    }
  }

  getLabelColor(): string {
    return this.attrs.uptrendColor
  }
}

Supertrend.prototype.defaults = {
  uptrendColor: COLORS.Uptrend,
  downtrendColor: COLORS.Downtrend,
}

export default Supertrend
