import { Options, SMAConfig } from '../overlays/configs/sma'
import { MainCalculation, OnValueCalculated } from './main'

type DefaultCalculatedValuesType = {
  sma: number[]
}

export class SMACalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = SMAConfig
  declare options: Options

  calculate(onValueCalculated?: OnValueCalculated<typeof this.calculatedValues>) {
    const { close } = this.quote
    const lastIndex = close.length - 1
    this._calculatedValues = this.getDefaultCalculatedValues()

    let sum = 0
    for (let i = 0; i < this.options.period; i++) {
      sum += close[i]
    }
    for (let i = this.options.period; i <= lastIndex; i++) {
      sum = sum - close[i - this.options.period] + close[i]
      this.calculatedValues.sma[i] = sum / this.options.period

      if (onValueCalculated?.(this.calculatedValues, i) === false) {
        break
      }
    }
  }
}
