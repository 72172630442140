import utils from '../utils'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { ADXConfig, Attrs } from './configs/adx'
import Indicator from './indicator'

class ADX extends Indicator<Attrs> {
  static config = ADXConfig

  declare period: number
  pdi: number[] = []
  mdi: number[] = []
  adx: number[] = []

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (this.isComputeNecessary()) {
      let adx, amdm, apdm, mdi, pdi
      this.pdi = []
      this.mdi = []
      this.adx = []
      let atr = (apdm = amdm = adx = 0)
      for (let i = this.period; i < this.data.close.length; i++) {
        const deltaHigh = this.data.high[i] - this.data.high[i - 1]
        const deltaLow = this.data.low[i - 1] - this.data.low[i]

        let pdm = 0
        if (deltaHigh > 0 && deltaHigh > deltaLow) {
          pdm = deltaHigh
        }

        let mdm = 0
        if (deltaLow > 0 && deltaLow > deltaHigh) {
          mdm = deltaLow
        }

        const tr = utils.max([
          this.data.high[i] - this.data.low[i],
          Math.abs(this.data.high[i] - this.data.close[i - 1]),
          Math.abs(this.data.low[i] - this.data.close[i - 1]),
        ])

        atr = (atr * (this.period - 1) + tr) / this.period
        apdm = (apdm * (this.period - 1) + pdm) / this.period
        amdm = (amdm * (this.period - 1) + mdm) / this.period

        pdi = atr > 0 ? (apdm * 100) / atr : 0
        mdi = atr > 0 ? (amdm * 100) / atr : 0
        const di = Math.abs(pdi - mdi)
        const dx = pdi + mdi > 0 ? (di * 100) / (pdi + mdi) : 0
        adx = (adx * (this.period - 1) + dx) / this.period

        this.pdi[i] = pdi
        this.mdi[i] = mdi
        this.adx[i] = adx
      }
      this.lastValue = this.adx.last() ?? null
    }
    const { min, max } =
      this.pdi.length > 0 || this.mdi.length > 0 || this.adx.length > 0
        ? this.computeVisibleMinMax(this.pdi, this.mdi, this.adx)
        : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getLineColors() {
    return {
      pdi: '#2eb12e',
      mdi: '#d43737',
      adx: this.getChartLayoutSettings().IndicatorSettings.general.Colors.line,
    }
  }

  getValueLabelsAtIndex(index: number) {
    const lineColors = this.getLineColors()
    const dataIndex = this.data.barToDataIndex[index]
    return [
      { color: lineColors.pdi, text: this.getValueLabel(this.pdi[dataIndex]) },
      { color: lineColors.mdi, text: this.getValueLabel(this.mdi[dataIndex]) },
      { color: lineColors.adx, text: this.getValueLabel(this.adx[dataIndex]) },
    ]
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    if (this.data.close.length === 0) return

    const lineColors = this.getLineColors()

    context.translate(0.5, 0.5)
    context.set('strokeStyle', lineColors.pdi)
    context.beginPath()

    const drawInVisibleAreaProps = {
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      fromIndexOffset: this.period,
    }
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(this.pdi[i])))
      },
    })

    context.stroke()

    context.set('strokeStyle', lineColors.mdi)
    context.beginPath()
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(this.mdi[i])))
      },
    })
    context.stroke()
    context.set('strokeStyle', lineColors.adx)
    context.beginPath()

    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(this.adx[i])))
      },
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 14,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: ADXConfig.label,
      inputs: ADXConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default ADX
