/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import * as Ariakit from '@ariakit/react'
import classNames from 'classnames'
import React from 'react'

import { BUTTON_DEFAULT_ELEMENT, Button, ButtonComponentProps } from '../button'
import { Icon, IconNameType, isValidIconName } from '../icon'

// Must have the same keys as ButtonSize
export enum TabSpacing {
  xxsmall = 'space-x-0.5', // 2px spacing
  xsmall = 'space-x-1', // 4px spacing
  none = 'space-x-1.5', // 6px spacing
  small = 'space-x-1.5', // 6px spacing
  regular = 'space-x-1.5', // 6px spacing
  medium = 'space-x-2', // 8px spacing
  large = 'space-x-2', // 8px spacing
}

function getRightContent(content: JSX.Element | IconNameType | undefined, isActive: boolean) {
  if (isValidIconName(content)) return <Icon name={content} />
  else if (React.isValidElement(content))
    return React.cloneElement(content as React.ReactElement<{ active: boolean }>, {
      active: isActive,
    })
  else return content
}

const InternalTab = React.forwardRef(function InternalTab<
  TagType extends React.ElementType = typeof BUTTON_DEFAULT_ELEMENT,
>(
  {
    rounding = 'none',
    theme = 'tab',
    size = 'medium',
    'aria-selected': ariaSelected,
    children,
    ...props
  }: Omit<ButtonComponentProps<TagType>, 'theme'>,
  ref: React.ForwardedRef<HTMLElement>
) {
  const spacing = TabSpacing[size]
  const active = Boolean(ariaSelected)
  const rightContent = getRightContent(props.rightContent, active)

  return (
    <Button
      // TS thinks the Omit type (due to spread above) doesn’t satisfy button props
      {...(props as ButtonComponentProps<TagType>)}
      ref={ref}
      active={active}
      theme={theme}
      size={size}
      rounding={rounding}
      rightContent={undefined}
      className={classNames('group/badge', props.className)}
      contentClass={classNames(spacing, 'flex', props.contentClass)}
    >
      <span>{children}</span>
      {rightContent}
    </Button>
  )
})

export function Tab<TagType extends React.ElementType = typeof BUTTON_DEFAULT_ELEMENT>(
  props: React.PropsWithChildren<ButtonComponentProps<TagType>>
) {
  return <Ariakit.Tab {...props} render={<InternalTab />} />
}
