import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: number
}

export type Attrs = Options

export class AroConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Aro
  static abbreviation = 'aro'
  static label = 'Aroon'
  static shortLabel = 'Aroon'
  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
  static calculatedValuesOrder = ['aroonUp', 'aroonDown', 'oscillator'] as const
  static calculatedValuesLabels = {
    aroonUp: 'Aroon Up',
    aroonDown: 'Aroon Down',
    oscillator: 'Oscillator',
  }

  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
