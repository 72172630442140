import { EMACalculation } from '../indicator-calculation/ema'
import { Attrs, EMAConfig } from './configs/ema'
import Overlay from './overlay'

class EMA extends Overlay<Attrs> {
  static config = EMAConfig

  set(obj: Partial<Attrs>) {
    super.set(obj)
    const { period } = obj
    if (typeof period === 'string') {
      this.attrs.period = parseInt(period, 10)
      this.trigger('change')
    }
    return this
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    const { leftOffset, width } = this.model.chart()

    context.translate(0.5, 0.5)
    context.beginPath()
    context.set('lineWidth', 1)
    context.set('strokeStyle', this.attrs.color)
    const emaCalculation = new EMACalculation({
      quote: this.data,
      options: {
        period: this.attrs.period,
      },
    })
    emaCalculation.calculate(({ ema }, dataIndex) => {
      const x = this.fx(dataIndex)
      if (this.fx(dataIndex + 1) + leftOffset < 0) {
        return
      }
      if (this.fx(dataIndex - 1) + leftOffset > width) {
        return false
      }
      context.lineTo(x, Math.round(this.fy(ema[dataIndex])))
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.attrs.period ?? 5,
        required: true,
        min: 1,
        max: 999999,
      },
      color: {
        type: 'color',
        label: 'Color',
        name: 'color',
        value: this.attrs.color ?? this.getFreeColor(),
      },
    }

    return {
      title: this.config.label,
      inputs: this.config.inputsOrder.map((item) => options[item as keyof Attrs]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      case 'color':
        return !!this.attrs[key] // use real color validation, we got isValidHexColor but it might be also RGBA
      default:
        return false
    }
  }
}

EMA.prototype.defaults = { color: '#ffa75f' }

export default EMA
