import utils from '../utils'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { Attrs, DchConfig } from './configs/dch'
import Overlay from './overlay'

const DEFAULT_PERIOD = 20

const COLORS = {
  ChannelStroke: '#69c1ea',
  ChannelFill: '#69C1EA12',
  MedianBandStroke: '#ffa75f',
}

class DonchianChannels extends Overlay<Attrs> {
  static config = DchConfig

  set(obj: Partial<Attrs>) {
    super.set(obj)
    const { period } = obj
    if (typeof period === 'string') {
      this.attrs.period = parseInt(period, 10)
      this.trigger('change')
    }
    return this
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    const { leftOffset, width } = this.model.chart()
    const { period } = this.attrs
    const upperBand: number[] = []
    const lowerBand: number[] = []
    const medianBand: number[] = []
    let firstBarToRender = -1
    let lastBarToRender = 0
    if (this.data.close.length === undefined) {
      return
    }

    context.save()
    context.translate(0.5, 0.5)
    context.set('lineWidth', 1)
    context.set('strokeStyle', COLORS.ChannelStroke)
    context.set('fillStyle', COLORS.ChannelFill)

    //upper band + clip
    context.beginPath()
    drawInVisibleArea({
      quote: this.data,
      paneModel: this.model,
      leftOffset,
      width,
      drawBarCallback: (i, x) => {
        firstBarToRender = firstBarToRender === -1 ? i : firstBarToRender
        upperBand[i] = utils.max(this.data.high.slice(Math.max(0, i - period), i + 1))
        lowerBand[i] = utils.min(this.data.low.slice(Math.max(0, i - period), i + 1))
        medianBand[i] = (upperBand[i] + lowerBand[i]) / 2

        context.lineTo(x, Math.round(this.fy(upperBand[i])))
        lastBarToRender = i
      },
    })
    context.stroke()
    context.lineTo(this.fx(lastBarToRender), context.canvas.height)
    context.lineTo(this.fx(firstBarToRender), context.canvas.height)
    context.clip()

    // lower band + fill
    context.beginPath()
    for (let i = firstBarToRender; i <= lastBarToRender; i++) {
      const x = this.fx(i)
      context.lineTo(x, Math.round(this.fy(lowerBand[i])))
    }
    context.stroke()
    context.lineTo(this.fx(lastBarToRender), 0)
    context.lineTo(0, 0)
    context.fill()

    // median band
    context.set('strokeStyle', COLORS.MedianBandStroke)
    context.beginPath()
    for (let i = firstBarToRender; i <= lastBarToRender; i++) {
      const x = this.fx(i)
      context.lineTo(x, Math.round(this.fy(medianBand[i])))
    }
    context.stroke()
    context.restore()
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.attrs.period ?? DEFAULT_PERIOD,
        required: true,
        min: 1,
        max: 999999,
      },
      channelStrokeColor: {
        type: 'color',
        label: 'Channels Stroke Color',
        name: 'channelStrokeColor',
        value: this.attrs.channelStrokeColor ?? this.getFreeColor(),
      },
      channelFillColor: {
        type: 'color',
        label: 'Channels Fill Color',
        name: 'channelFillColor',
        value: this.attrs.channelFillColor ?? this.getFreeColor(),
      },
      medianBandColor: {
        type: 'color',
        label: 'Median Band Color',
        name: 'medianBandColor',
        value: this.attrs.medianBandColor ?? this.getFreeColor(),
      },
    }

    return {
      title: DchConfig.label,
      inputs: DchConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      case 'channelStrokeColor':
      case 'channelFillColor':
      case 'medianBandColor':
        return true
      default:
        return false
    }
  }

  getLabelColor() {
    return this.attrs.medianBandColor
  }
}

DonchianChannels.prototype.defaults = {
  medianBandColor: COLORS.MedianBandStroke,
  channelStrokeColor: COLORS.ChannelStroke,
  channelFillColor: COLORS.ChannelFill,
}

export default DonchianChannels
