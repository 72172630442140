import {
  ChartConfigChartPaneElement,
  IModalConfig,
  IModalConfigInput,
  ObjectHash,
  RequireByKey,
} from '../../types/shared'
import Element from '../canvas/element'
import BaseChart from '../charts/base_chart'
import { OverlayType } from '../constants/common'
import Quote from '../models/quote'
import { convertColorToHEX, getRGBAValueFromValidColorString } from '../utils/colors'
import { getParsedIntegersFromPeriodString, isInRange, isPositiveInteger } from '../utils/helpers'
import OverlayConfig from './configs/overlayBaseConfig'

export interface IOverlayIsValid {
  getIsValid(key: string): boolean | void
}

const DEFAULT_LABEL_COLOR = '#0587cd'

class Overlay<Attrs extends ObjectHash = ObjectHash> extends Element<Attrs> implements IOverlayIsValid {
  static config = OverlayConfig

  static getNumOfBarsBuffer({ period }: RequireByKey<ChartConfigChartPaneElement, 'period'>) {
    const [periodInt] = getParsedIntegersFromPeriodString(period)
    return periodInt
  }

  declare data: Quote
  declare chart: BaseChart

  get type() {
    return (this.constructor as typeof Overlay).config.type as OverlayType
  }

  get label() {
    return (this.constructor as typeof Overlay).config.label
  }

  get config() {
    return (this.constructor as typeof Overlay).config
  }

  getLabelColor() {
    return this.attrs.color ? convertColorToHEX(this.attrs.color) : DEFAULT_LABEL_COLOR
  }

  getFreeColor() {
    return this.getChartLayoutSettings().ColorsSettings[0]
    // TODO proper implementation - example how this could be done:
    // let colorsUsed = []
    // for (let overlay of this.chart.overlays) {
    //   let object = overlay.getModalConfig().inputs
    //   for (let _ in object) {
    //     let input = object[_]
    //     if (input.type === 'color') {
    //       colorsUsed.push(input.value)
    //     }
    //   }
    // }
    // for (let color of colors) {
    //   if (!colorsUsed.includes(color)) {
    //     return color
    //   }
    // }
    // return colors[0]
  }

  renderContent(_?: RenderingContext2DType) {
    this.data = this.model.chart().quote()
  }

  fx = (x: number) => this.model.scale.x(this.data.barIndex[x])

  getIsValid(_: string): boolean | undefined {
    throw Error('Implement')
  }

  getIsNumberInputValid({ key, integerOnly = true }: { key: string; integerOnly?: boolean }): boolean {
    const input = this.getModalConfig().inputs.find(({ name }: { name: string }) => key === name)
    return !!input && isInRange(input) && (!integerOnly || isPositiveInteger(input.value))
  }

  isRendered() {
    return true
  }

  isRenderedOverlaysLabel() {
    return this.isRendered()
  }

  getModalConfig(): IModalConfig {
    return {
      title: '',
      inputs: [],
      inputsErrorMessages: {},
    }
  }

  toConfig() {
    const colors: string[] = []
    const periods: string[] = []

    this.getModalConfig().inputs.forEach((input: IModalConfigInput) => {
      if (input.type === 'color') {
        colors.push(getRGBAValueFromValidColorString(input.value.toString()))
      } else {
        periods.push(input.value.toString())
      }
    })

    return { type: this.type, period: periods.join(','), color: colors.join('|') }
  }

  toString() {
    return this.config.getShortLabelWithAttrs(this.attrs)
  }
}

export default Overlay
