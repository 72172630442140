import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export type Options = {
  step: number
  maxStep: number
}

export interface Attrs extends Options {
  period: string
  risingColor: string
  fallingColor: string
}

export class SARConfig extends BaseConfig {
  static type = OverlayType.Sar
  static abbreviation = 'sar'
  static label = 'Parabolic SAR'
  static shortLabel = 'SAR'
  static optionsOrder = ['step', 'maxStep'] as const
  static optionsLabels = {
    step: 'Step',
    maxStep: 'Maximum Step',
  }

  static inputsOrder = [...this.optionsOrder, 'risingColor', 'fallingColor'] as Array<keyof Omit<Attrs, 'period'>>
  static inputsLabels = { ...this.optionsLabels, risingColor: 'Rising Color', fallingColor: 'Falling Color' }
}
