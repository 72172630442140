import { AroConfig, Attrs } from '../indicators/configs/aro'
import { MainCalculation, OnValueCalculated } from './main'

type DefaultCalculatedValuesType = {
  aroonUp: number[]
  aroonDown: number[]
  oscillator: number[]
}

export class AroonCalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = AroConfig

  calculate(onValueCalculated?: OnValueCalculated<typeof this.calculatedValues>) {
    const { period } = this.options
    const { low, high } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    for (let i = period; i < high.length; i++) {
      const highPeriod = high.slice(i - period, i + 1)
      const highestInPeriod = Math.max(...highPeriod)
      const periodSinceHigh = period - highPeriod.lastIndexOf(highestInPeriod)
      this._calculatedValues.aroonUp[i] = 100 * ((period - periodSinceHigh) / period)

      const lowPeriod = low.slice(i - period, i + 1)
      const lowestInPeriod = Math.min(...lowPeriod)
      const periodSinceLow = period - lowPeriod.lastIndexOf(lowestInPeriod)
      this._calculatedValues.aroonDown[i] = 100 * ((period - periodSinceLow) / period)

      this._calculatedValues.oscillator[i] = this._calculatedValues.aroonUp[i] - this._calculatedValues.aroonDown[i]

      if (onValueCalculated?.(this.calculatedValues, i) === false) {
        break
      }
    }
  }
}
