import { ButtonComponentProps, Icon, Select, SelectListProps } from '@finviz/website'
import * as React from 'react'

import ChartLayout from '../../models/chart_layout'
import { useChartTypes } from './use-chart-types'

interface Props extends Pick<ButtonComponentProps, 'theme' | 'size'> {
  isPremium: boolean
  model: ChartLayout
  rounding: SelectListProps['rounding']
}

export function ChartTypesSelect({ isPremium, model, ...props }: Props) {
  const { chartTypes, getCurrentType } = useChartTypes({ isPremium, model })
  const currentType = getCurrentType()
  const [selectedType, setSelectedType] = React.useState(currentType)
  const icon = chartTypes.find((item) => item.value === selectedType)?.icon

  React.useEffect(() => {
    setSelectedType(currentType)
  }, [currentType])

  return (
    <Select
      {...props}
      data-testid="chart-type-select"
      aria-label="Chart type"
      className="w-[7.75rem]"
      value={selectedType}
      items={chartTypes}
      contentClass="truncate"
      leftContent={
        props.theme === 'chip' ? (
          icon
        ) : (
          <Icon name={chartTypes.find((item) => item.value === selectedType)?.icon} className="text-gray-300" />
        )
      }
      nativeSelect="mobile"
      onChange={(item) => setSelectedType(item.value)}
    />
  )
}
