import { Attrs, RSIConfig } from '../indicators/configs/rsi'
import { MainCalculation, OnValueCalculated } from './main'

type DefaultCalculatedValuesType = {
  rsi: number[]
}

export class RsiCalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = RSIConfig

  calculate(onValueCalculated?: OnValueCalculated<typeof this.calculatedValues>) {
    const { period } = this.options
    const { close } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    let sumu = 0
    let sumd = 0
    let u: number, d: number, ad: number, au: number
    for (let i = 1; i < close.length; i++) {
      u = d = 0
      if (close[i] > close[i - 1]) {
        u = close[i] - close[i - 1]
      }
      if (close[i] < close[i - 1]) {
        d = close[i - 1] - close[i]
      }

      if (i <= period) {
        sumu += u
        sumd += d
        au = sumu / i
        ad = sumd / i
      } else {
        au = (au! * (period - 1) + u) / period
        ad = (ad! * (period - 1) + d) / period
      }

      const rs = ad > 0 ? au / ad : 1
      this.calculatedValues.rsi[i] = 100 - 100 * (1 / (1 + rs))

      if (onValueCalculated?.(this.calculatedValues, i) === false) {
        break
      }
    }
  }
}
