import PaneModel from '../models/pane'
import { getBarWidthWithMargin } from '../utils/chart'
import { Attrs, HILOConfig } from './configs/hilo'
import Overlay from './overlay'

class HighLow extends Overlay<Attrs> {
  static config = HILOConfig

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.renderContent = this.renderContent.bind(this)
  }

  set(obj: Partial<Attrs>) {
    super.set(obj)
    const { period } = obj
    if (typeof period === 'string') {
      this.attrs.period = parseInt(period, 10)
      this.trigger('change')
    }
    return this
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    const chartModel = this.model.chart()
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const { leftOffset, width, zoomFactor } = chartModel
    const barWidth = getBarWidthWithMargin({
      zoomFactor,
      chartLayout: chartModel.chart_layout(),
    })
    const halfWidth = barWidth / 2
    const period = Math.min(this.attrs.period, this.data.close.length - 1)
    let firstBarToRender
    let lastBarToRender = 0
    context.translate(0.5, 0.5)
    context.set('fillStyle', Colors.overlayTransparentBackground)
    context.beginPath()
    context.set('strokeStyle', this.attrs.highColor)
    let prevHigh = -1
    for (let i = 0; i < this.data.close.length; i++) {
      let highestHigh = this.data.high[i]
      for (let j = i - period + 1; j <= i; j++) {
        if (this.data.high[j] > highestHigh) {
          highestHigh = this.data.high[j]
        }
      }

      const x = this.fx(i)
      if (this.fx(i + 1) + leftOffset < -10) {
        prevHigh = highestHigh
        continue
      }
      if (firstBarToRender === undefined) firstBarToRender = i
      if (this.fx(i - 1) + leftOffset > width) {
        break
      }

      const diff = this.data.barIndex[i] - this.data.barIndex[lastBarToRender]
      if (diff > 1 && prevHigh !== highestHigh && prevHigh > 0) {
        context.lineTo(Math.round(x - halfWidth), Math.round(this.fy(prevHigh)))
      }
      context.lineTo(Math.round(x - halfWidth), Math.round(this.fy(highestHigh)))
      context.lineTo(Math.round(x + halfWidth), Math.round(this.fy(highestHigh)))

      prevHigh = highestHigh
      lastBarToRender = i
    }
    context.stroke()
    if (firstBarToRender === undefined) return

    // return to start and fill with background color
    context.lineTo(this.fx(lastBarToRender) + halfWidth, 0)
    context.lineTo(this.fx(firstBarToRender) - halfWidth, 0)
    context.fill()

    context.beginPath()
    context.set('strokeStyle', this.attrs.lowColor)
    let prevLow = -1
    let prevBar = firstBarToRender
    for (let i = firstBarToRender; i <= lastBarToRender; i++) {
      let lowestLow = this.data.low[i]
      for (let j = i - period + 1; j <= i; j++) {
        if (this.data.low[j] < lowestLow) {
          lowestLow = this.data.low[j]
        }
      }

      const x = this.fx(i)
      const diff = this.data.barIndex[i] - this.data.barIndex[prevBar]
      if (diff > 1 && prevLow !== lowestLow && prevLow > 0) {
        context.lineTo(Math.round(x - halfWidth), Math.round(this.fy(prevLow)))
      }
      context.lineTo(Math.round(x - halfWidth), Math.round(this.fy(lowestLow)))
      context.lineTo(Math.round(x + halfWidth), Math.round(this.fy(lowestLow)))

      prevLow = lowestLow
      prevBar = i
    }
    context.stroke()
    context.translate(-0.5, -0.5)

    // return to start and fill with background color
    context.lineTo(this.fx(lastBarToRender), context.canvas.height)
    context.lineTo(this.fx(firstBarToRender) - halfWidth, context.canvas.height)
    context.fill()
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.attrs.period ?? 50,
        required: true,
        min: 1,
        max: 999999,
      },
      highColor: {
        type: 'color',
        label: 'High Color',
        name: 'highColor',
        value: this.attrs.highColor ?? this.getFreeColor(),
      },
      lowColor: {
        type: 'color',
        label: 'Low Color',
        name: 'lowColor',
        value: this.attrs.lowColor ?? this.getFreeColor(),
      },
    }

    return {
      title: HILOConfig.label,
      inputs: HILOConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      case 'highColor':
      case 'lowColor':
        // Some users have wrong colors which break the form validation
        return true
      default:
        return false
    }
  }

  getLabelColor() {
    return this.attrs.highColor
  }
}

HighLow.prototype.defaults = {
  lowColor: '#69c1ea',
  highColor: '#d386df',
}

export default HighLow
