import { Attrs, FIConfig } from '../indicators/configs/fi'
import { ExponentialMovingAverage } from './ema'
import { MainCalculation, OnValueCalculated } from './main'

type DefaultCalculatedValuesType = {
  fi: number[]
}

export class FICalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = FIConfig

  calculate(onValueCalculated?: OnValueCalculated<typeof this.calculatedValues>) {
    const { period } = this.options
    const { close, volume } = this.quote
    const ema = new ExponentialMovingAverage(period)
    this._calculatedValues = this.getDefaultCalculatedValues()

    for (let index = 1; index < close.length; index++) {
      const value = ((close[index] - close[index - 1]) * volume[index]) / 1000000
      ema.add(value)
      this.calculatedValues.fi[index] = ema.value

      if (onValueCalculated?.(this.calculatedValues, index) === false) {
        break
      }
    }
  }
}
