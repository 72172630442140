import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: number
}

export type Attrs = Options

export class ADXConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Adx
  static abbreviation = 'adx'
  static label = 'ADX'
  static shortLabel = 'ADX'
  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
}
