import { Attrs, CCIConfig } from './configs/cci'
import Indicator from './indicator'

const constant = 0.015
class CommodityChannelIndex extends Indicator<Attrs> {
  static config = CCIConfig

  cci: number[] = []
  declare period: number

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (this.isComputeNecessary()) {
      let smaCount
      const tp = []
      this.cci = []
      let smaSum = (smaCount = 0)
      for (let i = 0; i < this.data.close.length; i++) {
        let devCount
        const typicalPrice = (this.data.close[i] + this.data.low[i] + this.data.high[i]) / 3
        tp[i] = typicalPrice

        smaSum += typicalPrice
        smaCount++
        if (smaCount > this.period) {
          smaSum -= tp[i - this.period]
          smaCount--
        }
        const sma = smaSum / smaCount

        if (i < this.period - 1) {
          continue
        }

        let devSum = (devCount = 0)
        for (let j = i - this.period + 1; j <= i; j++) {
          devSum += Math.abs(tp[j] - sma)
          devCount++
        }

        const dev = devSum / devCount
        this.cci[i] = 0
        if (dev > 0) {
          this.cci[i] = (typicalPrice - sma) / (constant * dev)
        }
      }

      this.lastValue = this.cci.last() ?? null
    }

    const { min, max } = this.cci.length > 0 ? this.computeVisibleMinMax(this.cci) : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    return this.getOversoldOverboughtValueLabelsAtIndex(index, this.cci)
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    this.renderOversoldOverbought(context, this.cci, this.period, -100, 0, 100)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 20,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: CCIConfig.label,
      inputs: CCIConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default CommodityChannelIndex
