import { ChartConfigChartPaneElement, RequireByKey } from '../../types/shared'
import AverageTrueRange from '../helpers/atr'
import { ExponentialMovingAverage } from '../indicator-calculation/ema'
import { Attrs, KCHConfig } from './configs/kch'
import Overlay from './overlay'

const DEFAULT_PARAMETERS = {
  emaPeriod: 20,
  multiplier: 2.0,
  atrPeriod: 10,
}

const COLORS = {
  Stroke: '#69C1EA',
  Fill: '#69C1EA33',
}

function parsePeriod(period: string) {
  const values = period.split(',')
  const emaPeriod = parseInt(values[0])
  const multiplier = parseFloat(values[1])
  const atrPeriod = parseInt(values[2])

  return [emaPeriod, multiplier, atrPeriod]
}

class KeltnerChannel extends Overlay<Attrs> {
  static config = KCHConfig
  static getNumOfBarsBuffer({ period }: RequireByKey<ChartConfigChartPaneElement, 'period'>) {
    return Math.max(...parsePeriod(period)) * 2
  }

  set(obj: Partial<Attrs>) {
    super.set(obj)
    if (
      typeof this.attrs.period === 'string' &&
      (this.attrs.emaPeriod === undefined || this.attrs.multiplier === undefined || this.attrs.atrPeriod === undefined)
    ) {
      const [emaPeriod, multiplier, atrPeriod] = parsePeriod(this.attrs.period)
      this.attrs.emaPeriod = emaPeriod
      this.attrs.multiplier = multiplier
      this.attrs.atrPeriod = atrPeriod
      this.trigger('change')
    }
    return this
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    const { leftOffset, width } = this.model.chart()
    const { emaPeriod, multiplier, atrPeriod, strokeColor, fillColor } = this.attrs
    const ema = new ExponentialMovingAverage(emaPeriod)
    const atr = new AverageTrueRange(atrPeriod)
    const middleLine: number[] = []
    const upperBand: number[] = []
    const lowerBand: number[] = []
    const start = Math.max(emaPeriod, atrPeriod)
    let firstBarToRender
    let lastBarToRender = 0

    context.save()
    context.translate(0.5, 0.5)
    context.set('lineWidth', 1)
    context.set('strokeStyle', strokeColor)
    context.set('fillStyle', fillColor)

    //top line + clip
    context.beginPath()
    for (let i = 1; i < this.data.close.length; i++) {
      ema.add(this.data.close[i])
      atr.add(this.data.high[i], this.data.low[i], this.data.close[i - 1])
      if (i < start) {
        continue
      }

      const x = this.fx(i)
      if (this.fx(i + 1) + leftOffset < 0) {
        continue
      }
      if (this.fx(i - 1) + leftOffset > width) {
        break
      }
      if (firstBarToRender === undefined) firstBarToRender = i

      middleLine[i] = ema.value
      upperBand[i] = ema.value + multiplier * atr.value
      lowerBand[i] = ema.value - multiplier * atr.value

      context.lineTo(x, Math.round(this.fy(upperBand[i])))
      lastBarToRender = i
    }
    if (firstBarToRender === undefined) {
      context.restore()
      return
    }
    context.stroke()
    context.lineTo(this.fx(lastBarToRender), context.canvas.height)
    context.lineTo(this.fx(firstBarToRender), context.canvas.height)
    context.clip()

    // bottom line + fill
    context.beginPath()
    for (let i = firstBarToRender; i <= lastBarToRender; i++) {
      const x = this.fx(i)
      context.lineTo(x, Math.round(this.fy(lowerBand[i])))
    }
    context.stroke()
    context.lineTo(this.fx(lastBarToRender), 0)
    context.lineTo(0, 0)
    context.fill()

    // middle line
    context.beginPath()
    for (let i = firstBarToRender; i <= lastBarToRender; i++) {
      const x = this.fx(i)
      context.lineTo(x, Math.round(this.fy(middleLine[i])))
    }
    context.stroke()
    context.restore()
  }

  getModalConfig() {
    const options = {
      emaPeriod: {
        type: 'number',
        label: 'EMA Period',
        name: 'emaPeriod',
        value: this.attrs.emaPeriod ?? DEFAULT_PARAMETERS.emaPeriod,
        required: true,
        min: 1,
        max: 999999,
      },
      multiplier: {
        type: 'number',
        label: 'Multiplier',
        name: 'multiplier',
        value: this.attrs.multiplier ?? DEFAULT_PARAMETERS.multiplier,
        required: true,
        step: 0.1,
        min: 0.0,
        max: 999,
      },
      atrPeriod: {
        type: 'number',
        label: 'ATR Period',
        name: 'atrPeriod',
        value: this.attrs.atrPeriod ?? DEFAULT_PARAMETERS.atrPeriod,
        required: true,
        min: 1,
        max: 999999,
      },
      strokeColor: {
        type: 'color',
        label: 'Stroke Color',
        name: 'strokeColor',
        value: this.attrs.strokeColor ?? this.getFreeColor(),
      },
      fillColor: {
        type: 'color',
        label: 'Fill Color',
        name: 'fillColor',
        value: this.attrs.fillColor ?? this.getFreeColor(),
      },
    }

    return {
      title: KCHConfig.label,
      inputs: KCHConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        emaPeriod: `${options.emaPeriod.label} must be a whole number between ${options.emaPeriod.min} and ${options.emaPeriod.max}`,
        multiplier: `${options.multiplier.label} must be a number between ${options.multiplier.min} and ${options.multiplier.max}`,
        atrPeriod: `${options.atrPeriod.label} must be a whole number between ${options.atrPeriod.min} and ${options.atrPeriod.max}`,
      },
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'emaPeriod':
        return this.getIsNumberInputValid({ key })
      case 'multiplier':
        return this.getIsNumberInputValid({ key, integerOnly: false })
      case 'atrPeriod':
        return this.getIsNumberInputValid({ key })
      case 'strokeColor':
      case 'fillColor':
        return true
      default:
        return false
    }
  }

  getLabelColor() {
    return this.attrs.strokeColor
  }
}

KeltnerChannel.prototype.defaults = { strokeColor: COLORS.Stroke, fillColor: COLORS.Fill }

export default KeltnerChannel
