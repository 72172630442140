import { EMAConfig, Options } from '../overlays/configs/ema'
import { MainCalculation, OnValueCalculated } from './main'

export class ExponentialMovingAverage {
  period: number
  multiplier: number
  sum: number
  count: number
  value: number

  constructor(period: number) {
    this.period = period
    this.multiplier = 2 / (this.period + 1)
    this.sum = 0
    this.count = 0
    this.value = 0
  }

  add(d: number) {
    if (this.count < this.period) {
      this.sum += d
      this.count++
      this.value = this.sum / this.count
    } else {
      this.value = d * this.multiplier + this.value * (1 - this.multiplier)
    }
  }
}

type DefaultCalculatedValuesType = {
  ema: number[]
}

export class EMACalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = EMAConfig

  calculate(onValueCalculated?: OnValueCalculated<typeof this.calculatedValues>) {
    const { period } = this.options
    const { close } = this.quote
    const lastIndex = close.length - 1
    const ema = new ExponentialMovingAverage(period)
    this._calculatedValues = this.getDefaultCalculatedValues()

    for (let index = 0; index <= lastIndex; index++) {
      ema.add(close[index])
      this.calculatedValues.ema[index] = ema.value

      if (onValueCalculated?.(this.calculatedValues, index) === false) {
        break
      }
    }
  }
}
