import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export type Options = { period: number }

export interface Attrs extends Options {
  color: string
}

export class SMAConfig extends BaseConfig {
  static type = OverlayType.Sma
  static abbreviation = 'sma'
  static label = 'Simple Moving Average'
  static shortLabel = 'SMA'
  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = ['period', 'color'] as Array<keyof Attrs>
  static inputsLabels = { period: 'Period', color: 'Color' }
  static calculatedValuesOrder = ['sma'] as const
  static calculatedValuesLabels = { sma: 'SMA' }
  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
