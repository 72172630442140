import Spine, { Collection } from '@finviz/spine'
import omit from 'lodash.omit'

import { Position, TodoObjectHashAnyType } from '../../types/shared'
import CanvasElement from '../canvas/element'
import { ChartEventBadgeType, PANE_HEIGHT_DEFAULT } from '../constants/common'
import Chart from './chart'
import ChartEventElement from './chart-event-element'
import Element from './element'
import { getChartLayoutSettings } from './settings'
import { rebuildElementZIndexes } from './utils'

export interface ScaleAxis {
  (index: number): number
  invert: (x: number) => number
  domain: () => [currentDomainMax: number, currentDomainMin: number]
  width: (x: number) => number
  height: (y: number) => number
  ticks: (y: number) => number[]
}

const CHART_EVENT_BADGE_TYPE_ORDER = [
  ChartEventBadgeType.Split,
  ChartEventBadgeType.Dividends,
  ChartEventBadgeType.EarningsPositive,
  ChartEventBadgeType.EarningsNegative,
  ChartEventBadgeType.EarningsNeutral,
  ChartEventBadgeType.EarningsFuture,
]

class Pane extends Spine.Model {
  static initClass(
    elementModel: typeof Element,
    chartEventElementModel: typeof ChartEventElement,
    chartModel: typeof Chart
  ) {
    this.configure('Pane', 'height', 'scale', 'scaleRange', 'instance', 'selection')
    this.hasMany('elements', elementModel)
    this.hasMany('chartEvents', chartEventElementModel)
    this.belongsTo('mainElement', elementModel)
    this.belongsTo('chart', chartModel)
  }

  declare id: string
  declare chart_id: string
  declare height: number
  declare scale: {
    x: ScaleAxis
    y: ScaleAxis
  }

  declare instance: any
  declare chart: () => Chart
  declare mainElement: <T extends Element = Element>() => T | null
  declare elements: <T extends Element = Element>() => Collection<T>
  declare chartEvents: <T extends ChartEventElement = ChartEventElement>() => Collection<T>
  selection: CanvasElement | null = null
  scaleRange: { min: number; max: number } | null = null

  destroyCascade(options?: TodoObjectHashAnyType) {
    this.elements()
      .all()
      .forEach((element) => {
        element.destroyCascade()
      })
    return this.destroy(options)
  }

  toObject() {
    const elements = this.elements()
      .all()
      .map((el) => el.toObject())
    return {
      height: this.height,
      scale: this.scale,
      scaleRange: this.scaleRange,
      elements,
    }
  }

  toConfig(omitKeys = [] as string[]) {
    const elements = this.elements()
      .all()
      .map((el) => el.toConfig())
    return omit(
      {
        height: this.height,
        scaleRange: this.scaleRange,
        elements,
      },
      omitKeys
    )
  }

  move(direction: Position) {
    const chartPanes = this.chart().getAllPanes()
    const chartPane = chartPanes.find((pane) => !pane.getIsIndicatorPane())
    const targetPane = chartPanes.find((pane) => this.eql(pane))

    if (!chartPane || !targetPane) return

    const currentChartPaneRecords = Pane.records.filter((pane) => pane.chart_id === targetPane.chart_id)
    const ohterChartPaneRecords = Pane.records.filter((pane) => pane.chart_id !== targetPane.chart_id)

    const targetRecordIndex = currentChartPaneRecords.findIndex((record) => record.id === targetPane.id)
    const arrayWithoutTargetRecord = currentChartPaneRecords.filter((_, index) => index !== targetRecordIndex)
    const chartRecordIndex = arrayWithoutTargetRecord.findIndex((record) => record.id === chartPane.id)

    const panesAbove = arrayWithoutTargetRecord.slice(0, chartRecordIndex)
    const panesBelow = arrayWithoutTargetRecord.slice(chartRecordIndex + 1)
    if (direction === Position.Above) {
      panesAbove.push(currentChartPaneRecords[targetRecordIndex])
      panesAbove.sort((a, b) => a.getNumericId() - b.getNumericId())
    }
    if (direction === Position.Below) {
      panesBelow.push(currentChartPaneRecords[targetRecordIndex])
      panesBelow.sort((a, b) => a.getNumericId() - b.getNumericId())
    }

    Pane.records = ohterChartPaneRecords.concat([
      ...panesAbove,
      arrayWithoutTargetRecord[chartRecordIndex],
      ...panesBelow,
    ])
    this.chart().trigger('change', this.chart())
  }

  getChartLayoutSettings() {
    return getChartLayoutSettings(this.chart().chart_layout())
  }

  getAllElements() {
    return this.elements()
      .all()
      .sort((a, b) => a.zIndex - b.zIndex)
  }

  getAllChartEvents(isSorted = true) {
    const chartEvents = [...this.chartEvents().all()]
    return isSorted ? chartEvents.sort((a, b) => a.zIndex - b.zIndex) : chartEvents
  }

  getChartOrIndicatorElement() {
    return this.getAllElements().find((el) => el.isChart() || el.isIndicator()) ?? null
  }

  getIsIndicatorPane() {
    return !!this.mainElement()?.isIndicator()
  }

  getIsChartPane() {
    return !!this.mainElement()?.isChart()
  }

  resetHeight() {
    if (this.getIsIndicatorPane()) {
      this.updateAttribute('height', PANE_HEIGHT_DEFAULT.indicator)
    }
    if (this.getIsChartPane()) {
      this.updateAttribute('height', PANE_HEIGHT_DEFAULT.chart)
    }
  }

  getIndexInChart() {
    return this.chart()
      .getAllPanes()
      .findIndex((chartPane) => chartPane.eql(this))
  }

  getElementZIndexRange() {
    let max = -1
    let min = 1
    this.getAllElements().forEach((el) => {
      if (el.zIndex > max) {
        max = el.zIndex
      }
      if (el.zIndex < min) {
        min = el.zIndex
      }
    })
    return { min, max }
  }

  getBelowAboveZeroElements() {
    const allPaneElements = this.getAllElements()
    const elementsBelowZero: Element[] = []
    const elementsAboveZero: Element[] = []
    allPaneElements.forEach((el) => {
      if (el.zIndex < 0) {
        elementsBelowZero.push(el)
      }
      if (el.zIndex > 0) {
        elementsAboveZero.push(el)
      }
    })

    return { elementsBelowZero, elementsAboveZero }
  }

  normalizeZIndexes() {
    const { elementsBelowZero, elementsAboveZero } = this.getBelowAboveZeroElements()
    rebuildElementZIndexes({ elementsArray: elementsBelowZero, isBelowZero: true })
    rebuildElementZIndexes({ elementsArray: elementsAboveZero, isBelowZero: false })
  }

  getQuoteRawTicker() {
    return this.chart().getQuoteRawTicker()
  }

  getNumericId() {
    return Number(this.id.replace('c-', ''))
  }

  updateChartEventsZIndexes() {
    if (!this.scale || !this.scale.hasOwnProperty('x')) {
      return
    }

    const chartEvents = this.getAllChartEvents(false).sort(
      (a, b) => a.instance.attrs.positionTimestamps!.x - b.instance.attrs.positionTimestamps!.x
    )
    const hoveredChartEvent = chartEvents.find((chartEvent) => chartEvent.instance.isHovered)
    const openedChartEvent = chartEvents.find((chartEvent) => chartEvent.instance.isOpen)

    const hoveredTypeChartEventElements: ChartEventElement[] = []
    const openedTypeChartEventElements: ChartEventElement[] = []
    const nonActiveChartEventElements: ChartEventElement[] = []

    chartEvents.forEach((chartEvent, index) => {
      const chartEventsOnSameXPoint = chartEvents.filter(
        (chartEvent_B, i) =>
          i < index && Math.round(chartEvent.instance.attrs.x) === Math.round(chartEvent_B!.instance.attrs.x)
      )
      chartEvent.updateAttributes({ yIndex: chartEventsOnSameXPoint.length })
      const isHovered = chartEvent.instance === hoveredChartEvent?.instance
      const isOpened = chartEvent.instance === openedChartEvent?.instance
      const isHoveredType = chartEvent.instance.type === hoveredChartEvent?.instance.type
      const isOpenType = chartEvent.instance.type === openedChartEvent?.instance.type
      if (!isHovered || !isOpened) {
        if (isHoveredType) {
          hoveredTypeChartEventElements.push(chartEvent)
        } else if (isOpenType) {
          openedTypeChartEventElements.push(chartEvent)
        } else {
          nonActiveChartEventElements.push(chartEvent)
        }
      }
    })

    nonActiveChartEventElements.sort(
      (a, b) =>
        CHART_EVENT_BADGE_TYPE_ORDER.indexOf(a.instance.getChartEvenBadgeType()) -
        CHART_EVENT_BADGE_TYPE_ORDER.indexOf(b.instance.getChartEvenBadgeType())
    )

    const chartEventElements = [
      ...nonActiveChartEventElements,
      ...openedTypeChartEventElements,
      openedChartEvent,
      ...hoveredTypeChartEventElements,
      hoveredChartEvent,
    ].filter(Boolean)
    chartEventElements.forEach((chartEvent, index) => {
      chartEvent!.updateAttributes({ zIndex: index })
    })
  }
}

export default Pane
