import { VWapCalculation } from '../indicator-calculation/vwap'
import PaneModel from '../models/pane'
import { Attrs, VWAPConfig } from './configs/vwap'
import Overlay from './overlay'

class VWAP extends Overlay<Attrs> {
  static config = VWAPConfig

  static getNumOfBarsBuffer() {
    return 1
  }

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.attrs.period = 5
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    if (!this.isRendered()) {
      return
    }

    const { leftOffset, width } = this.model.chart()
    context.translate(0.5, 0.5)
    context.beginPath()
    context.set('lineWidth', 1)
    context.set('strokeStyle', this.attrs.color)

    const vwapCalculation = new VWapCalculation({
      quote: this.data,
      options: { period: this.attrs.period },
    })

    vwapCalculation.calculate(({ vwap }, dataIndex) => {
      const x = this.fx(dataIndex)
      if (this.fx(dataIndex + 1) + leftOffset < 0) {
        return // continue
      }
      if (this.fx(dataIndex - 1) + leftOffset > width) {
        return false // break
      }
      const y = Math.round(this.fy(vwap[dataIndex]))
      context.lineTo(x, y)
    })

    context.stroke()
    context.translate(-0.5, -0.5)
  }

  getModalConfig() {
    const options = {
      color: {
        type: 'color',
        label: 'Color',
        name: 'color',
        value: this.attrs.color ?? this.getFreeColor(),
      },
    }

    return {
      title: VWAPConfig.label,
      inputs: VWAPConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {},
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'color':
        // Some users have wrong colors which break the form validation
        return true
      default:
        return false
    }
  }

  isRendered() {
    return this.model.chart().quote().isIntraday
  }

  isRenderedOverlaysLabel() {
    return this.isRendered()
  }

  toString() {
    return VWAPConfig.shortLabel
  }
}

VWAP.prototype.defaults = { color: '#9467bd' }

export default VWAP
