import * as React from 'react'

import { Notification, useNotification } from '../components/notification'
import { Paragraph } from '../components/typography'
import { useIsMounted } from '../hooks/use-is-mounted'
import { importWithRetry } from './import-with-retry'

const cache: any = {}
const errorsCache: any = {}

enum ErrorSeverity {
  medium,
  high,
}

function defaultCaptureException(exception: Error, captureContext?: { extra: Record<string, unknown> }) {
  if (process.env.NODE_ENV === 'development') {
    console.error(exception)
  } else {
    window.Sentry.captureException(exception, captureContext)
  }
}

export interface ControlledModuleConfig {
  /**
   * Suspense mode shouldn’t be used together with controlled loading as it could lead to bugs.
   * If you know what you’re doing, add an ignore comment explaining the decision
   */
  isSuspenseMode?: never
  shouldLoadModule?: boolean
}
export interface SuspenseModuleConfig<SuspenseMode> {
  /**
   * Controlled loading shouldn’t be used together with suspense mode as it could lead to bugs.
   * If you know what you’re doing, add an ignore comment explaining the decision
   */
  shouldLoadModule?: never
  isSuspenseMode?: SuspenseMode
}

/**
 * Hook used to asynchronously load a module
 *
 * Usage: [wiki](https://github.com/finvizhq/Finviz-Website/wiki/Loading-modules-asynchronously#useasyncmodule)
 */
export function useAsyncModule<T, SuspenseMode extends boolean>({
  importFn,
  onError,
  errorSeverity,
  cacheKey,
  captureException = defaultCaptureException,
  shouldLoadModule = false,
  isSuspenseMode = false as SuspenseMode,
}: {
  importFn: () => Promise<T>
  onError?: () => void
  cacheKey: string
  errorSeverity?: keyof typeof ErrorSeverity
  captureException?: typeof defaultCaptureException
} & (ControlledModuleConfig | SuspenseModuleConfig<SuspenseMode>)) {
  const notificationContext = useNotification()
  const [isLoading, setIsLoading] = React.useState<boolean | null>(null)
  const [isError, setIsError] = React.useState(false)
  const getIsMounted = useIsMounted()

  const handleError = () => {
    onError?.()
    setIsError(true)

    if (errorSeverity === undefined) {
      return
    }

    if (ErrorSeverity[errorSeverity] === ErrorSeverity.high) {
      window.handleScriptNotLoaded()
      window.renderScriptNotLoaded()
    }

    if (ErrorSeverity[errorSeverity] === ErrorSeverity.medium) {
      notificationContext.show(
        <Notification timeoutInMs={6000}>
          <Paragraph className="max-w-[17rem]">
            One or more of the required application components couldn’t be loaded. <br /> Please try to repeat the
            action or{' '}
            <span className="cursor-pointer underline" onClick={() => window.location.reload()}>
              refresh the page
            </span>{' '}
            if the problem persists.
          </Paragraph>
        </Notification>
      )
    }
  }

  const loadModule = async () => {
    if (cache[cacheKey]) {
      return cache[cacheKey]
    }

    setIsLoading(true)

    try {
      cache[cacheKey] = await importWithRetry(importFn)
    } catch (error) {
      captureException(error as Error)
      handleError()
    } finally {
      if (getIsMounted()) {
        setIsLoading(false)
      }
    }

    return cache[cacheKey]
  }

  React.useEffect(() => {
    if (shouldLoadModule) {
      loadModule()
    }
    // We want to run it only on shouldLoadModule change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadModule])

  const cachedModule = cache[cacheKey]
  if (isSuspenseMode) {
    if (errorsCache[cacheKey]) throw errorsCache[cacheKey]
    if (!cachedModule) {
      throw importFn()
        .then((mod) => (cache[cacheKey] = mod))
        .catch((err) => {
          handleError()
          errorsCache[cacheKey] = err
        })
    }
  }

  return [cachedModule ?? null, { isLoading: !!cachedModule ? false : isLoading, loadModule, isError }] as [
    SuspenseMode extends true ? T : T | null,
    { isLoading: boolean; loadModule: () => Promise<T>; isError: boolean },
  ]
}

/**
 * HOC which wraps component in React.Suspense
 *
 * Usage: [wiki](https://github.com/finvizhq/Finviz-Website/wiki/Loading-modules-asynchronously#withsuspense)
 */
export function withSuspense<P extends Record<string, any>>(
  Component: React.ComponentType<P>,
  fallback: React.ReactNode = null
) {
  return function WrappedComponent(props: P) {
    return (
      <React.Suspense fallback={fallback}>
        <Component {...props} />
      </React.Suspense>
    )
  }
}
