import utils from '../utils'

class AverageTrueRange {
  period: number
  sum: number
  count: number
  value: number

  constructor(period: number) {
    this.period = period
    this.sum = 0
    this.count = 0
    this.value = 0
  }

  add(high: number, low: number, prevClose: number) {
    const tr = utils.max([high - low, Math.abs(high - prevClose), Math.abs(low - prevClose)])

    if (this.count < this.period) {
      this.sum += tr
      this.value = this.sum / (this.count + 1)
      if (this.count === this.period - 1) {
        this.sum /= this.period
      }
    } else {
      this.value = (this.value * (this.period - 1) + tr) / this.period
    }
    this.count++
  }
}

export default AverageTrueRange
