import Spine from '@finviz/spine'

export const defaults = {
  enabled: false,
  collapsed: true,
  duration: false,
  timestamp: true,
  logErrors: true,
  withStackTrace: false,
  level: 'log',
  logger: console,
  filterChanges: (model, attrs) => {
    const props = Object.keys(attrs)
    return (
      model.constructor.name === 'Mouse' ||
      model.constructor.name === 'Quote' ||
      (props.length === 1 && (props[0] === 'scale' || props[0] === 'leftOffset'))
    )
  },
  errorTransformer: (error) => error,
  titleFormatter: ({ timestamp, duration }, title, time, took) => {
    const parts = ['model']
    parts.push(`%c${String(title)}`)
    if (timestamp) {
      parts.push(`%c@ ${time}`)
    }
    if (duration) {
      parts.push(`%c(in ${took.toFixed(2)} ms)`)
    }
    return parts.join(' ')
  },
  colors: {
    title: () => 'inherit',
    prevState: () => '#9E9E9E',
    action: () => '#03A9F4',
    nextState: () => '#4CAF50',
    error: () => '#F20404',
  },
}

const repeat = (str, times) => new Array(times + 1).join(str)
const pad = (num, maxLength = 2) => repeat('0', maxLength - num.toString().length) + num
const formatTime = (time) =>
  `${pad(time.getHours())}:${pad(time.getMinutes())}:${pad(time.getSeconds())}.${pad(time.getMilliseconds(), 3)}`

export const printLogEntry = (logEntry, options = {}) => {
  const { logger, logErrors, titleFormatter, collapsed, timestamp, duration, withStackTrace, colors, level } = {
    ...defaults,
    ...options,
  }
  const { startedTime, title, prevState, data, error, nextState, took } = logEntry

  const formattedTime = formatTime(startedTime)
  const titleCSS = colors.title ? `color: ${colors.title(title)};` : ''
  const headerCSS = ['color: gray; font-weight: lighter;']
  headerCSS.push(titleCSS)
  if (timestamp) {
    headerCSS.push('color: gray; font-weight: lighter;')
  }
  if (duration) {
    headerCSS.push('color: gray; font-weight: lighter;')
  }
  const formattedTitle = titleFormatter(defaults, title, formattedTime, took)

  try {
    if (collapsed) {
      if (colors.title) {
        logger.groupCollapsed(`%c ${formattedTitle}`, ...headerCSS)
      } else {
        logger.groupCollapsed(formattedTitle)
      }
    } else if (colors.title) {
      logger.group(`%c ${formattedTitle}`, ...headerCSS)
    } else {
      logger.group(formattedTitle)
    }
  } catch (e) {
    logger.log(formattedTitle)
  }

  if (colors.prevState) {
    const styles = `color: ${colors.prevState(prevState)}; font-weight: bold`
    logger[level]('%c prev state', styles, prevState)
  } else {
    logger[level]('prev state', prevState)
  }

  if (colors.action) {
    const styles = `color: ${colors.action(data)}; font-weight: bold`
    logger[level]('%c arguments ', styles, data)
  } else {
    logger[level]('arguments ', data)
  }

  if (error && logErrors) {
    if (colors.error) {
      const styles = `color: ${colors.error(error, prevState)}; font-weight: bold;`
      logger['error']('%c error     ', styles, error)
    } else {
      logger['error']('error     ', error)
    }
  }

  if (colors.nextState) {
    const styles = `color: ${colors.nextState(nextState)}; font-weight: bold`
    logger[level]('%c next state', styles, nextState)
  } else {
    logger[level]('next state', nextState)
  }

  if (withStackTrace) {
    logger.groupCollapsed('TRACE')
    logger.trace()
    logger.groupEnd()
  }

  try {
    logger.groupEnd()
  } catch (e) {
    logger.log('—— log end ——')
  }
}

export const initialize = () => {
  window['logger'] = defaults

  const protoModelLoad = Spine.Model.prototype.load
  Spine.Model.prototype.load = function (attrs) {
    if (defaults.enabled && !defaults.filterChanges(this, attrs)) {
      const started = Date.now()
      const logEntry = {
        title: `${this.constructor.name}[#${this.id || this.cid}]`,
        started: started,
        startedTime: new Date(started),
        prevState: this.attributes(),
        data: attrs,
      }

      let returnedValue
      try {
        returnedValue = protoModelLoad.apply(this, arguments)
      } catch (e) {
        logEntry.error = e
      }

      logEntry.nextState = this.attributes()
      logEntry.took = Date.now() - logEntry.started

      printLogEntry(logEntry)
      return returnedValue
    } else {
      return protoModelLoad.apply(this, arguments)
    }
  }
}
