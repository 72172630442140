import { Attrs, SARConfig } from './configs/sar'
import Overlay from './overlay'

class SAR extends Overlay<Attrs> {
  static config = SARConfig

  static getNumOfBarsBuffer() {
    return 0
  }

  set(obj: Partial<Attrs>) {
    super.set(obj)
    if (typeof this.attrs.period === 'string' && (this.attrs.step === undefined || this.attrs.maxStep === undefined)) {
      const [step = 0, maxStep = 0] = this.attrs.period.split(',').map((x) => parseFloat(x))
      this.attrs.step = step
      this.attrs.maxStep = maxStep
    }
    this.trigger('change')
    return this
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    const data = this.data
    const deltaHigh = data.high[1] - data.high[0]
    const deltaLow = data.low[0] - data.low[1]
    const pdm = deltaHigh > 0 && deltaHigh > deltaLow ? deltaHigh : 0
    const mdm = deltaLow > 0 && deltaLow > deltaHigh ? deltaLow : 0
    let isLong = pdm >= mdm

    let sar = isLong ? data.low[0] : data.high[0]
    let ep = isLong ? data.high[1] : data.low[1]
    let newHigh = data.high[0]
    let newLow = data.low[0]
    let af = this.attrs.step

    for (let i = 1, end = data.close.length, asc = end >= 1; asc ? i < end : i > end; asc ? i++ : i--) {
      let sarToDraw
      const prevHigh = newHigh
      const prevLow = newLow
      newHigh = data.high[i]
      newLow = data.low[i]

      if (isLong) {
        if (newLow <= sar) {
          isLong = false
          sar = ep

          if (sar < prevHigh) {
            sar = prevHigh
          }
          if (sar < newHigh) {
            sar = newHigh
          }

          sarToDraw = sar

          af = this.attrs.step
          ep = newLow

          sar = sar + af * (ep - sar)

          if (sar < prevHigh) {
            sar = prevHigh
          }
          if (sar < newHigh) {
            sar = newHigh
          }
        } else {
          sarToDraw = sar

          if (newHigh > ep) {
            ep = newHigh
            af += this.attrs.step
            if (af > this.attrs.maxStep) {
              af = this.attrs.maxStep
            }
          }

          sar = sar + af * (ep - sar)

          if (sar > prevLow) {
            sar = prevLow
          }
          if (sar > newLow) {
            sar = newLow
          }
        }
      } else {
        if (newHigh >= sar) {
          isLong = true
          sar = ep

          if (sar > prevLow) {
            sar = prevLow
          }
          if (sar > newLow) {
            sar = newLow
          }

          sarToDraw = sar

          af = this.attrs.step
          ep = newHigh

          sar = sar + af * (ep - sar)

          if (sar > prevLow) {
            sar = prevLow
          }
          if (sar > newLow) {
            sar = newLow
          }
        } else {
          sarToDraw = sar

          if (newLow < ep) {
            ep = newLow
            af += this.attrs.step
            if (af > this.attrs.maxStep) {
              af = this.attrs.maxStep
            }
          }

          sar = sar + af * (ep - sar)

          if (sar < prevHigh) {
            sar = prevHigh
          }
          if (sar < newHigh) {
            sar = newHigh
          }
        }
      }

      const x = Math.round(this.fx(i) - 1.5)
      const y = Math.round(this.fy(sarToDraw) - 1.5)
      context.set('fillStyle', isLong ? this.attrs.risingColor : this.attrs.fallingColor)
      context.fillRect(x, y, 3, 3)
    }
  }

  getModalConfig() {
    const options = {
      step: {
        type: 'number',
        step: 0.01,
        min: 0.01,
        max: 999,
        label: 'Step',
        name: 'step',
        value: this.attrs.step ?? 0.02,
        required: true,
      },
      maxStep: {
        type: 'number',
        step: 0.01,
        min: 0.01,
        max: 999,
        label: 'Maximum Step',
        name: 'maxStep',
        value: this.attrs.maxStep ?? 0.2,
        required: true,
      },
      risingColor: {
        type: 'color',
        label: 'Rising Color',
        name: 'risingColor',
        value: this.attrs.risingColor ?? this.getFreeColor(),
        customizable: false,
      },
      fallingColor: {
        type: 'color',
        label: 'Falling Color',
        name: 'fallingColor',
        value: this.attrs.fallingColor ?? this.getFreeColor(),
        customizable: false,
      },
    }

    return {
      title: SARConfig.label,
      inputs: SARConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        step: `${options.step.label} must be a number between ${options.step.min} and ${options.step.max}`,
        maxStep: `${options.maxStep.label} must be a number between ${options.maxStep.min} and ${options.maxStep.max}`,
      },
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'step':
      case 'maxStep': {
        return this.getIsNumberInputValid({ key, integerOnly: false })
      }
      case 'risingColor':
      case 'fallingColor':
        // Some users have wrong colors which break the form validation
        return true
      default:
        return false
    }
  }

  getLabelColor() {
    return this.attrs.risingColor
  }
}

SAR.prototype.defaults = {
  risingColor: '#69c1ea',
  fallingColor: '#d386df',
}

export default SAR
