import { ObjectHash } from '../../../types/shared'
import ElementBaseConfig from '../../canvas/ElementBaseConfig'
import { IndicatorType, QuoteFinancialAttachment } from '../../constants/common'

export default class IndicatorBaseConfig extends ElementBaseConfig {
  static type = IndicatorType.Indicator
  static abbreviation = 'ind'
  static label = 'Indicator'
  static shortLabel = 'Indicator'
  static optionsOrder = [] as readonly string[]
  static optionsLabels = {}
  static inputsOrder = [] as readonly string[]
  static inputsLabels = {}
  static calculatedValuesOrder = [] as readonly string[]
  static calculatedValuesLabels = {}
  static defaultCalculatedValueOption = undefined as undefined | string
  static quoteFinancialAttachments: QuoteFinancialAttachment[] = []

  static getShortLabelWithAttrs(attrs: ObjectHash) {
    return [this.shortLabel, this.optionsOrder.map((item) => attrs[item]).join(',')].filter((item) => !!item).join(' ')
  }

  get getShortLabelWithAttrs() {
    return (this.constructor as typeof IndicatorBaseConfig).getShortLabelWithAttrs
  }

  static getDefaultCalculatedValues() {
    return this.calculatedValuesOrder.reduce((prev, curr) => ({ ...prev, [curr]: [] }), {})
  }

  get getDefaultCalculatedValues() {
    return (this.constructor as typeof IndicatorBaseConfig).getDefaultCalculatedValues
  }
}
