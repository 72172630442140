import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export type Options = {
  period: number
  deviations: number
}

export interface Attrs extends Options {
  topColor?: string
  bottomColor?: string
}

export class BBConfig extends BaseConfig {
  static type = OverlayType.Bb
  static abbreviation = 'bb'
  static label = 'Bollinger Bands'
  static shortLabel = 'BB'
  static optionsOrder = ['period', 'deviations'] as const
  static optionsLabels = { period: 'Period', deviations: 'Deviations' }
  static inputsOrder = ['period', 'deviations', 'topColor', 'bottomColor'] as Array<keyof Attrs>
  static inputsLabels = {
    period: 'Period',
    deviations: 'Deviations',
    topColor: 'Top Color',
    bottomColor: 'Bottom Color',
  }

  static calculatedValuesOrder = ['upperBand', 'sma', 'lowerBand'] as const
  static calculatedValuesLabels = {
    sma: 'SMA',
    upperBand: 'Upper Band',
    lowerBand: 'Lower Band',
  }

  static defaultCalculatedValueOption = this.calculatedValuesOrder[1]
}
