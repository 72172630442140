import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: number
}

export type Attrs = Options

export class RSIConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Rsi
  static abbreviation = 'rsi'
  static label = 'Relative Strength Index'
  static shortLabel = 'RSI'
  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
  static calculatedValuesOrder = ['rsi'] as const
  static calculatedValuesLabels = { rsi: 'RSI' }
  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
