import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export type Options = { period: number }

export interface Attrs extends Options {
  color: string
}

export class VWAPConfig extends BaseConfig {
  static type = OverlayType.Vwap
  static abbreviation = 'vwap'
  static label = 'VWAP (intraday charts)'
  static shortLabel = 'VWAP'
  static inputsOrder = ['color'] as Array<keyof Omit<Attrs, 'period'>>
  static inputsLabels = { color: 'Color' }
  static calculatedValuesOrder = ['vwap'] as const
  static calculatedValuesLabels = { vwap: 'VWAP' }
  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
