import { Attrs, MFIConfig } from './configs/mfi'
import Indicator from './indicator'

class MFI extends Indicator<Attrs> {
  static config = MFIConfig

  mfi: number[] = []
  declare period: number

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (!this.isComputeNecessary()) return

    let negativeMoneyFlow
    const mf = []
    this.mfi = []
    let prevTypicalPrice = (this.data.close[0] + this.data.low[0] + this.data.high[0]) / 3
    let positiveMoneyFlow = (negativeMoneyFlow = 0)
    for (let i = 1; i < this.data.close.length; i++) {
      const typicalPrice = (this.data.close[i] + this.data.low[i] + this.data.high[i]) / 3
      const moneyFlow = typicalPrice * this.data.volume[i]

      if (typicalPrice > prevTypicalPrice) {
        positiveMoneyFlow += moneyFlow
        mf[i] = moneyFlow
      } else if (typicalPrice < prevTypicalPrice) {
        negativeMoneyFlow += moneyFlow
        mf[i] = -moneyFlow
      }

      if (i > this.period) {
        const oldMF = mf[i - this.period]
        if (oldMF > 0) {
          positiveMoneyFlow -= oldMF
        } else if (oldMF < 0) {
          negativeMoneyFlow += oldMF
        }

        if (positiveMoneyFlow + negativeMoneyFlow > 0) {
          this.mfi[i] = (positiveMoneyFlow * 100) / (positiveMoneyFlow + negativeMoneyFlow)
        } else {
          this.mfi[i] = 50
        }
      }

      prevTypicalPrice = typicalPrice
    }

    this.lastValue = this.mfi.last() ?? null
    const { min, max } = this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    return this.getOversoldOverboughtValueLabelsAtIndex(index, this.mfi)
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    this.renderOversoldOverbought(context, this.mfi, this.period, 20, 50, 80)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 14,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: MFIConfig.label,
      inputs: MFIConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default MFI
